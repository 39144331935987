import store from "../store/index.js"
import jwt from 'vue-jwt-decode'

export default {
    async saveSession(token, save = true) {
        const tokenData = jwt.decode(token)
        if (tokenData) {
            if (save) {
                localStorage.setItem('token', token);
            }
            const login = tokenData.login
            const group = tokenData.group
            store.commit('writeSession', {login, group, token})
            return group
        }
    },
    async clearSession() {
        localStorage.removeItem('token')
        store.commit('clearSession')
    },
    async checkSession() { //Check auth state on starting app
        const token = localStorage.getItem('token')
        if (token) {
            this.saveSession(token, false)
        } else {
            this.clearSession()
        }
    }
}