<template>
    <div class="wrapper">
        <div class="center_div">
            <b-form-group id="login-group" label="Логін:" label-for="login" label-cols="4" label-cols-md="3">
                <b-form-input id="login" type="text" required autofocus v-model="login"></b-form-input>
            </b-form-group>
            <b-form-group id="password-group" label="Пароль:" label-for="password" label-cols="4" label-cols-md="3">
                <b-form-input id="password" type="password" required v-model="password"></b-form-input>
            </b-form-group>
            <div class="text-center">
                <b-btn @click="request" variant="primary" class="enter_btn">Вхід</b-btn>
            </div>
        </div>
    </div>
</template>
<script>
import auth from '../helper/auth'
import fetched from '../helper/fetched'
export default {
    data() {
        return {
            login: 'admin',
            password: '1111'
        }
    },
    methods: {
        async request() {
            const response = await fetch(this.$apiUrl + 'auth/login', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    login: this.login,
                    password: this.password
                })
            })
            const data = await response.json()
            if (data.status) {
                const group = await auth.saveSession(data.token)
                if (group == 'admin') {
                    this.$router.push('/admin')
                } else {
                    this.$router.push('/')
                }
            }
        }
    },
    async mounted() {
        try {
            const result = await fetched.get(this.$apiUrl + 'check')
            if (result.status) {
                const group = this.$store.state.group
                if (group == 'admin') {
                    this.$router.push('/admin')
                } else {
                    this.$router.push('/')
                }
            }
        } catch (e) { 
            //console.log()
        }
    }
}
</script>
<style scoped>
.wrapper {
    width: 100%;
    height: 100vh;
    background: linear-gradient(#cfeafb, 10%, #ffffff);
    padding: 150px 50px;
}

.center_div {
    width: 300px;
    margin: 0 auto;
}

.enter_btn {
    width: 130px;
}
</style>