import Vue from 'vue'
import store from '../store'

export default {
    //Fetch get request to api
    async get(url, data = false) {
        const request = new URL(url)
        if (data) {
            request.search = new URLSearchParams(data);
        }
        try {
            const response = await fetch(request, {
                headers: {
                    'Authorization': 'Bearer ' + store.state.token
                }
            })
            if (response.status == 401) {
                store.dispatch('goToLoginPage')
                return
            }
            const result = await response.json()
            return result
        } catch (e) {
            store.dispatch('showToast', 'Error connection to server')
            console.log(e.message)
            return { status: false, message: "Request error", error: e }
        }

    },
    /**
     * Fetch post request tot api
     * @param {String} url url path to api
     * @param {Array<object>} data Post data array
     * @returns {Array<object>} 
     */
    async post(url, data = false) {
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + store.state.token,
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify(data)
            })
            if (response.status !== 200) {
                throw `Request status: ${response.statusText}`
            }
            return await response.json();
        } catch (e) {
            store.dispatch('showToast', 'Error connection to server')
            console.log(e)
            return { status: false, message: e }
        }

    }


}