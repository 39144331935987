<template>
    <b-container>
        <b-card title="Прайс" style="max-width: 20rem; float: left" class="mt-3 mr-3">
            <b-card-text>
                Завантажити свіжий прайс та зробити замовлення
            </b-card-text>
            <b-btn to="/price" variant="primary">Перейти</b-btn>
        </b-card>
        <b-card title="Корзина" style="max-width: 20rem; float: left" class="mt-3 mr-3">
            <b-card-text> Переглянути поточне замовлення </b-card-text>
            <b-btn to="/checkout" variant="primary">Перейти</b-btn>
        </b-card>
        <b-card title="Історія замовлень" style="max-width: 20rem; float: left" class="mt-3 mr-3">
            <b-card-text> Історія замовлень </b-card-text>
            <b-btn to="/checkout" variant="primary">Перейти</b-btn>
        </b-card>
    </b-container>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
