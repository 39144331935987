<template>
    <b-container fluid>
        <b-row class="mt-3">
            <b-col class="ml-5">
                <b-row>
                    <b-table :items="order_items" :fields="order_fields" stripped small hover bordered
                        head-variant="dark" selectable select-mode="single" thead-class="head_class" class="tables"
                        :per-page="per_page" :current-page="current_page" @row-clicked="selectRow">
                        <template #cell(PROCESSED)="data">
                            <template v-if="data.value">
                                <input type="checkbox" checked disabled />
                            </template>
                            <template v-else>
                                <input type="checkbox" disabled />
                            </template>
                        </template>
                    </b-table>
                </b-row>
                <b-row><b-col>
                        <b-pagination :total-rows="total_row" v-model="current_page" align="fill" :per-page="per_page"
                            size="sm"></b-pagination>
                    </b-col></b-row>
            </b-col>
            <b-col class="mr-5">
                <b-table :items="detail_items" :fields="detail_fields" stripped small hover bordered head-variant="dark"
                    thead-class="head_class" class="tables">
                    <template #cell(QTYDONE)="data">
                        <template v-if="data.item.QTYDONE == data.item.QTY">
                            <span>{{ data.value }}</span>
                        </template>
                        <template v-else>
                            <span style="color: #ff0000">{{ data.value }}</span>
                        </template>
                    </template>
                </b-table>

            </b-col>
        </b-row>
    </b-container>
</template>
<script>

import fetched from '@/helper/fetched'

export default {
    data() {
        return {
            order_items: [],
            order_fields: [
                { key: "ID", label: "ID", sortable: false, tdClass: "text-center" },
                { key: "DATE", label: "Дата", sortable: false, tdClass: "text-center" },
                { key: "ADDRESS", label: "Адреса", class: "text-nowrap"},
                { key: "PROCESSED", label: "Опрацьовано", sortable: false, tdClass: "text-center" },
                { key: "CNT", label: "К-сть позицій", sortable: false, tdClass: "text-right" }
            ],
            detail_items: [],
            detail_fields: [
                { key: "GOODS_NAME", label: "Назва" },
                { key: "QTY", label: "К-сть замовлено", tdClass: "text-center" },
                { key: "QTYDONE", label: "К-сть прийнято", tdClass: "text-center" },
                { key: "PRICE", label: "Ціна", tdClass: "text-right" }
            ],
            current_page: 1,
            per_page: 20
        }
    },
    name: "InvoicesView",
    methods: {
        async selectRow(item) {
            const data = await fetched.get(this.$apiUrl + `cl/getDetails/${item.ID}`)
            this.detail_items = data.rows
        }
    },
    async mounted() {
        const data = await fetched.get(this.$apiUrl + 'cl/getOrders')
        this.order_items = data.rows
    },
    computed: {
        total_row() {
            return this.order_items.length
        }
    }
}

</script>
<style scoped>
.tables {
    font-size: 0.8em
}
</style>