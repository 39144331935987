import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        login: "",
        group: 0,
        token: null
    },
    getters: {
    },
    mutations: {
        writeSession(state, data) {
            state.logged = true
            state.login = data.login
            state.token = data.token
            state.group = data.group
        },
        clearSession(state) {
            state.logged = false
            state.login = ""
            state.token = ""
            state.group = 0
        }
    },
    actions: {
        // eslint-disable-next-line no-empty-pattern
        showToast({ }, txt) {
            this._vm.$bvToast.toast(txt, {
                variant: 'danger',
                title: 'Error',
                solid: true,
                noCloseButton: true,
                noAutoHide: false
            })
        },
        // eslint-disable-next-line no-empty-pattern
        showToastFull({ }, data) {
            const type = ("type" in data) ? data.type : 'success'
            // const title = data.hasOwnProperty('title') ? data.title : null
            this._vm.$bvToast.toast(data.text, {
                variant: type,
                title: data.title,
                solid: true,
                noCloseButton: true,
                noAutoHide: false
            })
        },
        goToLoginPage() {
            // console.log(this._vm)
            this.commit('clearSession')
            if (router.currentRoute.path != "/login") {
                router.push('/login')
            }
        }
    },
    modules: {
    }
})
